import { AimOutlined, EnvironmentFilled } from "@ant-design/icons";
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from "@monorepo/config";
import { useConfirmPickedForShipping, useGetOrders, useShipperAppPermissions } from "@monorepo/hooks";
import {
  CustomModalConfirmCustomer,
  CustomModalConfirmDeliver,
  CustomModalDeliverySuccess,
  CustomModalSelectShipper,
  SwiperMobile,
} from "@monorepo/shared-components";
import { formatCurrency, notify } from "@monorepo/utils";
import { Button, Image, Steps } from "antd";
import { t } from "i18next";
import { isNull } from "lodash";
import moment from "moment";
import { forwardRef } from "react";
import { ORDER_STATUS } from "~/pages/App/Order/constant";
import { ORDER_SHIPPING_TYPE } from "../../../constant";
import useOrder from "../../../hooks";
import PhoneComponent from "../PhoneComponent";

import "./index.scss";

const { Step } = Steps;

const fieldRenderHeader = [
  "id",
  "number",
  "deliveryDeadline",
  "receiverName",
  "receiverContactPhone",
  "receiverContactAddress",
  "orderPriority",
  "orderType",
  "customerPaymentMethod",
  "status",
  "senderAddress",
  "shippingType",
  "shipperTelephone",
  "shipperName",
  "shippingConfiguration",
  "shippingOrderCode",
  "codAmount",
  "orderParcel",
  "receiverContactName",
  "paymentMethod",
];

const fieldRenderBody = [
  "senderAddress",
  "note",
  "receiverContactAddress",
  "receiverName",
  "receiverContactPhone",
  "shippingType",
  "shippingConfiguration",
  "fileURLs",
  "statuses",
  "internalNote",
  "shipperTelephone",
  "shipperName",
  "receiverContactName",
  "orderParcel",
  "shippingVersion",
];

const OrderHeader = ({ orderHeader, orderStatus, refetch }) => {
  const {
    id,
    number,
    deliveryDeadline,
    receiverName,
    receiverContactPhone,
    receiverContactAddress,
    orderPriority,
    status,
    senderAddress,
    shippingType,
    shipperTelephone,
    shipperName,
    shippingConfiguration,
    codAmount,
    orderParcel,
    receiverContactName,
    paymentMethod,
  } = orderHeader;
  const renderTime = (time) => (time === "Invalid date" ? "" : time);
  const formatDate = (format) => moment(deliveryDeadline).format(format);

  const date = renderTime(formatDate("DD/MM/YYYY HH:mm"));
  const dateFormat = renderTime(formatDate(DATE_FORMAT));
  const timeFormat = renderTime(formatDate(TIME_FORMAT));

  const { canAssignShipper, canShipperDeliver } = useShipperAppPermissions();

  const { handleConfirmPickedForShipping, loading: loadingConfirmPickedForShipping } = useConfirmPickedForShipping();

  const SHIPPING_PROVIDER_TYPE = {
    INSTANT: t("order.shippingType.INSTANT"),
    STANDARD: t("order.shippingType.STANDARD"),
    ECONOMY: t("order.shippingType.ECONOMY"),
  };

  const orderPriorityTags = {
    LOW: {
      title: t("order.orderCard.low"),
      color: "#2246DC",
    },
    MEDIUM: {
      title: t("order.orderCard.medium"),
      color: "#00AB78",
    },
    HIGH: {
      title: t("order.orderCard.high"),
      color: "#D2434D",
    },
  };

  const orderStatusTags = {
    WAITING_FOR_DISPATCH: {
      title: t("order.orderStatus.waitingForDispatch"),
      backgroundColor: "#E9EEF5",
      color: "#5B6673",
    },
    WAITING: {
      title: t("order.orderStatus.waitingForShipping"),
      backgroundColor: "rgba(255, 217, 190, 0.59)",
      color: "#EF9351",
    },
    CONFIRM_PICKED: {
      title: t("order.orderStatus.delivering"),
      backgroundColor: "#E7F0FF",
      color: "#2246DC",
    },
    COMPLETED: {
      title: t("order.orderStatus.completed"),
      backgroundColor: "#EDF9F0",
      color: "#00AB78",
    },
  };

  const renderOrderPriority = (
    <b className="priority" style={{ color: `${orderPriorityTags[orderPriority].color}` }}>
      {`${orderPriorityTags[orderPriority].title}`}
    </b>
  );

  const renderStatus = (
    <div
      className="status-order"
      style={{
        backgroundColor: `${orderStatusTags[orderStatus]?.backgroundColor}`,
        color: `${orderStatusTags[orderStatus]?.color}`,
      }}
    >
      <b>{orderStatusTags[orderStatus]?.title}</b>
    </div>
  );

  const renderStatusSteps = (status) => {
    switch (status) {
      case ORDER_STATUS.WAITING_FOR_SHIPPING:
        return {
          senderStatus: "wait",
          receiverStatus: "wait",
        };
      case ORDER_STATUS.DELIVERING:
        return {
          senderStatus: "finish",
          receiverStatus: "wait",
        };
      case ORDER_STATUS.COMPLETED:
        return {
          senderStatus: "finish",
          receiverStatus: "finish",
        };
      default:
        return {
          senderStatus: "wait",
          receiverStatus: "wait",
        };
    }
  };

  const renderButton = () => {
    switch (orderStatus) {
      case "v1waitingForShippingNotSet":
        switch (shippingType) {
          case ORDER_SHIPPING_TYPE.BUS:
          case ORDER_SHIPPING_TYPE.URBAN_COD:
            return (
              <CustomModalSelectShipper
                orderID={id}
                labelButton={t("order.orderList.confirmShipping")}
                refetch={refetch}
              />
            );
          case ORDER_SHIPPING_TYPE.DELIVER_3PARTY:
            return (
              <CustomModalConfirmDeliver
                id={id}
                refetch={refetch}
                number={number}
                codAmount={codAmount}
                receiverName={receiverName || receiverContactName}
                paymentMethod={paymentMethod}
              />
            );
          case ORDER_SHIPPING_TYPE.PICK_UP_COUNTER:
            return (
              <CustomModalConfirmCustomer
                id={id}
                refetch={refetch}
                number={number}
                codAmount={codAmount}
                receiverName={receiverName || receiverContactName}
                paymentMethod={paymentMethod}
              />
            );
        }
        break;
      case "allVerWaitingForShippingWaiting":
        if (canAssignShipper && canShipperDeliver) {
          return (
            <>
              <CustomModalSelectShipper
                orderID={id}
                labelButton={t("order.orderList.changeShipper")}
                refetch={refetch}
                typeButton="secondary"
              />
              <Button onClick={handleConfirmPicked} loading={loadingConfirmPickedForShipping} type="primary">
                {t("order.orderList.orderConfirmation")}
              </Button>
            </>
          );
        }
        return canAssignShipper ? (
          <CustomModalSelectShipper orderID={id} labelButton={t("order.orderList.changeShipper")} refetch={refetch} />
        ) : (
          <Button onClick={handleConfirmPicked} loading={loadingConfirmPickedForShipping} type="primary">
            {t("order.orderList.orderConfirmation")}
          </Button>
        );
      case "v1deliveryConfirmPicked":
        if (canAssignShipper && canShipperDeliver) {
          return (
            <>
              <CustomModalSelectShipper
                orderID={id}
                labelButton={t("order.orderList.changeShipper")}
                refetch={refetch}
                typeButton="secondary"
              />
              <CustomModalDeliverySuccess
                orderID={id}
                refetch={refetch}
                codAmount={codAmount}
                number={number}
                receiverName={receiverName || receiverContactName}
                paymentMethod={paymentMethod}
              />
            </>
          );
        }
        if (canAssignShipper && !canShipperDeliver) {
          return (
            <CustomModalSelectShipper orderID={id} labelButton={t("order.orderList.changeShipper")} refetch={refetch} />
          );
        }
        if (!canAssignShipper && canShipperDeliver) {
          return <CustomModalDeliverySuccess orderID={id} refetch={refetch} />;
        }
        return;
      case "completed":
        return;
    }
  };

  const renderCurrency = formatCurrency(codAmount);

  const renderDescription = () => {
    switch (orderStatus) {
      case "v1waitingForShippingNotSet":
        switch (shippingType) {
          case ORDER_SHIPPING_TYPE.BUS:
          case ORDER_SHIPPING_TYPE.URBAN_COD:
            return (
              <>
                <b>{t("order.orderCard.sub")}</b>
                <span>{renderCurrency}</span>

                <b>{t("order.orderCard.promisedDeliverTime")}</b>
                <span>{date}</span>
              </>
            );
          case ORDER_SHIPPING_TYPE.DELIVER_3PARTY:
            return (
              <>
                <b>{t("order.orderCard.DVVC")}</b>
                <span>
                  {SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod]}
                  {SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod] &&
                    shippingConfiguration?.deliver3PartyConfig?.provider?.name &&
                    " - "}
                  {shippingConfiguration?.deliver3PartyConfig?.provider?.name}
                </span>

                <b>{t("order.orderCard.orderCode")}</b>
                <span>{orderParcel?.trackingCode || "- -"}</span>

                <b>{t("order.orderCard.promisedDeliverTime")}</b>
                <span>{date}</span>
              </>
            );
        }
        break;
      case "v1deliveryConfirmPicked":
      case "completed":
      case "allVerWaitingForShippingWaiting":
        switch (shippingType) {
          case ORDER_SHIPPING_TYPE.BUS:
          case ORDER_SHIPPING_TYPE.URBAN_COD:
            return (
              <>
                <b>{t("order.orderCard.sub")}</b>
                <span>{renderCurrency}</span>

                <b>{t("order.orderCard.promisedDeliverTime")}</b>
                <span>{date}</span>

                <b>{t("order.orderCard.shipper")}</b>
                <span>
                  {shipperName} - <PhoneComponent phoneNumber={shipperTelephone} />
                </span>
              </>
            );
          case ORDER_SHIPPING_TYPE.DELIVER_3PARTY:
            return (
              <>
                <b>{t("order.orderCard.DVVC")}</b>
                <span>
                  {SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod]}
                  {SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod] &&
                    shippingConfiguration?.deliver3PartyConfig?.provider?.name &&
                    " - "}
                  {shippingConfiguration?.deliver3PartyConfig?.provider?.name}
                </span>
                <b>{t("order.orderCard.orderCode")}</b>
                <span>{orderParcel?.trackingCode || "- -"}</span>
                <b>{t("order.orderCard.promisedDeliverTime")}</b>
                <span>{date}</span>
              </>
            );
        }
        break;
    }
  };

  const handleConfirmPicked = async (e) => {
    e.stopPropagation();
    try {
      await handleConfirmPickedForShipping({ orderIDs: [id] })
        .then(() =>
          notify.success({
            message: t("order.orderList.confirmPickupSuccess"),
            placement: "bottom",
          })
        )
        .then(() => refetch());
    } catch (e) {
      notify.error({ message: t("common.error"), placement: "bottom" });
    }
  };

  return (
    <div className="header-panel">
      <b>{number}</b>
      {renderOrderPriority}
      {renderStatus}
      <div className="description">{renderDescription()}</div>

      <Steps className="steps" size="small" direction="vertical">
        <Step
          status={renderStatusSteps(status).senderStatus}
          title={
            <div className="title">
              <span className="address">{senderAddress}</span>
              <span className="time">
                <span>_ _:_ _</span>
                <span>_ _/ _ _/ _ _ _ _</span>
              </span>
            </div>
          }
          icon={<AimOutlined />}
        />
        <Step
          status={renderStatusSteps(status).receiverStatus}
          title={
            <div className="title">
              <span className="address receiver">
                <span>{receiverContactAddress}</span>
                <span>
                  {receiverName || receiverContactName}-
                  <PhoneComponent phoneNumber={receiverContactPhone} onClick={(e) => e.stopPropagation()} />
                </span>
              </span>
              <span className="time receiver">
                <span>{timeFormat}</span>
                <span>{dateFormat}</span>
              </span>
            </div>
          }
          icon={<EnvironmentFilled />}
        />
      </Steps>
      <div className="button">{renderButton(status)}</div>
    </div>
  );
};

const OrderBody = ({ orderBody }) => {
  const {
    senderAddress,
    note,
    receiverContactAddress,
    receiverContactName,
    receiverContactPhone,
    shippingType,
    shippingConfiguration,
    fileURLs,
    shippingOrderCode,
    internalNote,
    shipperName,
    shipperTelephone,
    orderParcel,
  } = orderBody;
  const SHIPPING_PROVIDER_TYPE = {
    INSTANT: t("order.shippingType.INSTANT"),
    STANDARD: t("order.shippingType.STANDARD"),
    ECONOMY: t("order.shippingType.ECONOMY"),
  };

  const renderPickUpAddress = (shippingType) => {
    switch (shippingType) {
      case ORDER_SHIPPING_TYPE.URBAN_COD:
        return (
          <div className="pick-up-address">
            <b>{t("order.orderCard.senderAddress")}:</b>
            <p>{senderAddress}</p>
            {!isNull(note) && (
              <>
                <b>{t("order.orderCard.note")}:</b>
                <p>{note}</p>
              </>
            )}

            {!isNull(internalNote) && (
              <>
                <b>{t("order.orderCard.internalNote")}:</b>
                <p>{internalNote}</p>
              </>
            )}
          </div>
        );
      case ORDER_SHIPPING_TYPE.BUS:
        return (
          <div className="pick-up-address">
            <b>{t("order.orderCard.senderAddress")}:</b>
            <p>{senderAddress}</p>

            <b>{t("order.orderCard.deliveryMethod")}:</b>
            <p>{t("order.orderList.bus")}</p>

            <b>{t("order.orderCard.DVVC")}:</b>
            <p>{shippingConfiguration?.busConfig?.busName}</p>

            <b>{t("order.orderCard.receiverContactPhone")}:</b>
            <a href={`tel:${shippingConfiguration?.busConfig?.telephone}`}>
              {shippingConfiguration?.busConfig?.telephone}
            </a>

            {!isNull(note) && (
              <>
                <b>{t("order.orderCard.note")}:</b>
                <p>{note}</p>
              </>
            )}

            {!isNull(internalNote) && (
              <>
                <b>{t("order.orderCard.internalNote")}:</b>
                <p>{internalNote}</p>
              </>
            )}
          </div>
        );
      case ORDER_SHIPPING_TYPE.DELIVER_3PARTY:
        return (
          <div className="pick-up-address">
            <b>{t("order.orderCard.senderAddress")}:</b>
            <p>{senderAddress}</p>
            <b>{t("order.orderCard.DVVC")}:</b>
            <p>
              {SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod]}
              {SHIPPING_PROVIDER_TYPE[shippingConfiguration?.deliver3PartyConfig?.carrierMethod] &&
                shippingConfiguration?.deliver3PartyConfig?.provider?.name &&
                " - "}
              {shippingConfiguration?.deliver3PartyConfig?.provider?.name}
            </p>

            <b>{t("order.orderCard.orderCode")}:</b>
            <p>{shippingOrderCode || orderParcel?.trackingCode}</p>
            {!isNull(note) && (
              <>
                <b>{t("order.orderCard.note")}:</b>
                <p>{note}</p>
              </>
            )}
            {!isNull(internalNote) && (
              <>
                <b>{t("order.orderCard.internalNote")}:</b>
                <p>{internalNote}</p>
              </>
            )}
          </div>
        );
    }
  };

  const renderImages = fileURLs?.map((item, index) => (
    <Image key={index} src={item} alt="" width={70} height={70} style={{ objectFit: "cover" }} />
  ));
  const renderFormDeliveryImage = fileURLs ? (
    <div className="delivery-image">
      <b>{t("order.orderCard.image")}</b>
      <div className="image">{renderImages}</div>
    </div>
  ) : null;

  return (
    <div className="body-panel">
      {renderPickUpAddress(shippingType)}
      <div className="delivery-address">
        <b>{t("order.orderCard.receiverContactAddress")}:</b>
        <p>{receiverContactAddress}</p>

        <b>{t("order.orderCard.receiverName")}:</b>
        <p>{receiverContactName}</p>

        <b>{t("order.orderCard.receiverContactPhone")}:</b>
        <PhoneComponent phoneNumber={receiverContactPhone} />
      </div>
      {renderFormDeliveryImage}
    </div>
  );
};

const ListOrder = forwardRef(
  ({ handleChangeActiveIndex, defaultIndex, currentActiveTabKey, handleOrderStatus, refetchCountOrder }, ref) => {
    const { allParams } = useOrder();
    return (
      <SwiperMobile
        onIndexChange={handleChangeActiveIndex}
        defaultIndex={defaultIndex}
        currentActiveTabKey={currentActiveTabKey}
        allParams={allParams}
        useGetOrders={useGetOrders}
        handleOrderStatus={handleOrderStatus}
        OrderHeader={OrderHeader}
        fieldRenderHeader={fieldRenderHeader}
        OrderBody={OrderBody}
        fieldRenderBody={fieldRenderBody}
        ref={ref}
        refetchCountOrder={refetchCountOrder}
        isIncludeOrderItems={false}
      />
    );
  }
);

export default ListOrder;
